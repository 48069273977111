:root {
  --gold: #8c7b4b;

  --gray100: #e3e3e3;
  --gray200: #f2f2f2;
  --gray1000: #9ea2a7;

  --dark60: #1f1f1f99;
  --dark100: #1f1f1f;
  --dark200: #797979;
  --dark300: #000430;

  --light300: #eaedf1;

  --coral: #cd7764;

  --royalBlue1000: #0d1724;
  --royalBlue900: #242d38;
  --royalBlue800: #39414b;
  --royalBlue700: #51575f;
  --royalBlue600: #676c72;
  --royalBlue500: #7d8186;
  --royalBlue400: #939699;
  --royalBlue300: #aaacad;
  --royalBlue200: #c1c1c0;
  --royalBlue100: #d7d6d4;
  --royalBlue50: #e2e0dd;

  --sky300: #003bb3;
  --sky200: #0055ff;
  --sky100: #e6eeff;
  --flame200: #d91528;
  --light200: #f4f7fb;
  //! Neutral colors
  --white1000: #ffffff;
  --white750: #fafaf9;
  --white500: #f6f5f3;
  --white250: #f2f0ed;

  //! Secondary Colors
  --errorColor: #de0016;
  --successColor: #28a94b;
  --warningColor: #ff8936;

  //! Background Color
  --backgroundColor: #ffffff;

  --headerHeight: 68px;
  --headerToHeroSectionDistance: 64px;
  --lineHeightS: 1.2;
  --lineHeightM: 1.3;
  --lineHeightL: 1.5;
  --trTime: 0.3s;
  --spSize: 8px;
}
