* {
  box-sizing: border-box;
  outline: none;
  margin: 0;
  -webkit-appearance: none;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  font-synthesis: none !important;
}

html,
body {
  overflow-x: clip;

  &.popupIsOpen {
    overflow: hidden;
  }

  &.isBurgerMenuOpen {
    overflow: hidden;
  }
}
body {
  word-break: break-word;
  background-color: var(--backgroundColor);

  main {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - var(--headerHeight));
    z-index: 1;
    padding-top: var(--headerHeight);
    transition: padding-top var(--trTime);
  }

  &.hidden {
    overflow: hidden;
    max-height: 100vh;
  }
}

.raleway {
  --urbanist: var(--raleway);
  --merchant: var(--raleway);
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
}

.greek {
  --urbanist: var(--roboto);
  --merchant: var(--notoSerif);
  font-variant-numeric: lining-nums;
  font-feature-settings: 'lnum';
}

@layer {
  ul,
  fieldset {
    padding: 0;
    list-style: none;
    border: none;
  }

  textarea {
    resize: none;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
  }

  p {
    line-height: 1.5;
  }
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input:focus::placeholder {
  color: transparent;
}

input {
  outline: none;
}

@keyframes autofill {
  to {
    background-color: transparent;
  }
}

.icon {
  font-family: var(--icons) !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-decoration: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.oo-note:before {
  content: '\e944';
}
.oo-gitBranch:before {
  content: '\e943';
}
.oo-fileMagnifyingGlass:before {
  content: '\e940';
}
.oo-handCoins:before {
  content: '\e941';
}
.oo-XCircle:before {
  content: '\e942';
}
.oo-export:before {
  content: '\e93f';
}
.oo-listNumbers:before {
  content: '\e93b';
}
.oo-binoculars:before {
  content: '\e93c';
}
.oo-phoneList:before {
  content: '\e93d';
}
.oo-sealQuestion:before {
  content: '\e93e';
}
.oo-cicrleCheck:before {
  content: '\e93a';
}
.oo-UserFocus:before {
  content: '\e937';
}
.oo-fileMinus:before {
  content: '\e931';
}
.oo-arrowCircleUpRight:before {
  content: '\e932';
}
.oo-userSwitch:before {
  content: '\e933';
}
.oo-pen:before {
  content: '\e934';
}
.oo-listPlus:before {
  content: '\e935';
}
.oo-money:before {
  content: '\e936';
}
.oo-listStar:before {
  content: '\e92f';
}
.oo-presentation:before {
  content: '\e930';
}
.oo-globe:before {
  content: '\e929';
}
.oo-userSound:before {
  content: '\e92a';
}
.oo-crosshair:before {
  content: '\e92b';
}
.oo-flag:before {
  content: '\e92c';
}
.oo-sealCheck:before {
  content: '\e92d';
}
.oo-usersThree:before {
  content: '\e92e';
}
.oo-trash:before {
  content: '\e926';
}
.oo-pencil:before {
  content: '\e927';
}
.oo-airplane-takeoff:before {
  content: '\e918';
  color: #05f;
}
.oo-vault:before {
  content: '\e919';
  color: #05f;
}
.oo-device-mobile:before {
  content: '\e91a';
  color: #05f;
}
.oo-fork-knife:before {
  content: '\e91b';
  color: #05f;
}
.oo-diamond:before {
  content: '\e91c';
  color: #05f;
}
.oo-watch:before {
  content: '\e91d';
  color: #05f;
}
.oo-handbag:before {
  content: '\e91e';
  color: #05f;
}
.oo-island:before {
  content: '\e91f';
  color: #05f;
}
.oo-users-four:before {
  content: '\e920';
  color: #05f;
}
.oo-done:before {
  content: '\e921';
  color: #019f3c;
}
.oo-pencil1:before {
  content: '\e922';
  color: #016f2a;
}
.oo-check-wavy:before {
  content: '\e923';
}
.oo-teardrop:before {
  content: '\e924';
  color: #003bb3;
}
.oo-check-crooked:before {
  content: '\e925';
}
.oo-close_smooth:before {
  content: '\e900';
}
.oo-bookmark_fill:before {
  content: '\e901';
  color: #05f;
}
.oo-whatsapp:before {
  content: '\e902';
}
.oo-facebook_share:before {
  content: '\e903';
  color: #445371;
}
.oo-telegram:before {
  content: '\e904';
}
.oo-mail:before {
  content: '\e905';
}
.oo-info:before {
  content: '\e906';
}
.oo-arrow-short:before {
  content: '\e907';
  color: #445371;
}
.oo-bell:before {
  content: '\e908';
  color: #05f;
}
.oo-timer:before {
  content: '\e909';
}
.oo-copy:before {
  content: '\e90a';
}
.oo-share:before {
  content: '\e90b';
  color: #445371;
}
.oo-bookmark:before {
  content: '\e90c';
  color: #445371;
}
.oo-minus:before {
  content: '\e90d';
}
.oo-download:before {
  content: '\e90e';
}
.oo-check:before {
  content: '\e90f';
}
.oo-arrow:before {
  content: '\e910';
}
.oo-arrow-down:before {
  content: '\e911';
}
.oo-close:before {
  content: '\e912';
}
.oo-menu:before {
  content: '\e913';
}
.oo-youtube:before {
  content: '\e914';
}
.oo-facebook:before {
  content: '\e915';
}
.oo-instagram:before {
  content: '\e916';
}
.oo-twitter:before {
  content: '\e917';
}
.oo-bank:before {
  content: '\e938';
}
.oo-lock:before {
  content: '\e939';
}
.oo-link:before {
  content: '\e928';
}
.oo-globe1:before {
  content: '\e946';
}
.oo-speakerLow:before {
  content: '\e94b';
}
.oo-speakerHigh:before {
  content: '\e94c';
}
.oo-speakerMute:before {
  content: '\e947';
}
.oo-fullscreen:before {
  content: '\e948';
}
.oo-speaker:before {
  content: '\e949';
}
.oo-play:before {
  content: '\e94a';
}
.oo-pause:before {
  content: '\e94d';
}
.oo-restart:before {
  content: '\e94e';
}
.oo-playCircle:before {
  content: '\e952';
}
.oo-arrowFat:before {
  content: '\e953';
}
.oo-usersDoc:before {
  content: '\e954';
}
.oo-safe:before {
  content: '\e955';
}
