.hide-scroll {
  overflow: hidden;
  overscroll-behavior: none;
}

.font-argent {
  font-family: var(--argent);
  font-weight: 300;
}

.font-argent-light {
  font-family: var(--argent);
  font-weight: 500;
}

.font-argent-demi {
  font-family: var(--argent);
  font-weight: 700;
}

.font-avenir-regular {
  font-family: var(--avenir);
  font-weight: 400;
}

.font-avenir-medium {
  font-family: var(--avenir);
  font-weight: 500;
}

.font-avenir-demi {
  font-family: var(--avenir);
  font-weight: 600;
}

.font-urbanist {
  font-family: var(--urbanist);
}

.color-gold {
  color: var(--gold);

  &-bg {
    background-color: var(--gold);
  }
}
.gray-1000 {
  color: var(--gray1000);

  &-bg {
    background-color: var(--gray1000);
  }
}
.royal-blue-1000 {
  color: var(--royalBlue1000);

  &-bg {
    background-color: var(--royalBlue1000);
  }
}

.royal-blue-900 {
  color: var(--royalBlue900);

  &-bg {
    background-color: var(--royalBlue900);
  }
}

.royal-blue-800 {
  color: var(--royalBlue800);

  &-bg {
    background-color: var(--royalBlue800);
  }
}

.royal-blue-700 {
  color: var(--royalBlue700);

  &-bg {
    background-color: var(--royalBlue700);
  }
}

.royal-blue-600 {
  color: var(--royalBlue600);

  &-bg {
    background-color: var(--royalBlue600);
  }
}

.royal-blue-500 {
  color: var(--royalBlue500);

  &-bg {
    background-color: var(--royalBlue500);
  }
}

.royal-blue-400 {
  color: var(--royalBlue400);

  &-bg {
    background-color: var(--royalBlue400);
  }
}

.royal-blue-300 {
  color: var(--royalBlue300);

  &-bg {
    background-color: var(--royalBlue300);
  }
}

.royal-blue-200 {
  color: var(--royalBlue200);

  &-bg {
    background-color: var(--royalBlue200);
  }
}

.royal-blue-100 {
  color: var(--royalBlue100);

  &-bg {
    background-color: var(--royalBlue100);
  }
}

.royal-blue-50 {
  color: var(--royalBlue50);

  &-bg {
    background-color: var(--royalBlue50);
  }
}

.white-1000 {
  color: var(--white1000);

  &-bg {
    background-color: var(--white1000);
  }
}

.white-750 {
  color: var(--white750);

  &-bg {
    background-color: var(--white750);
  }
}

.white-500 {
  color: var(--white500);

  &-bg {
    background-color: var(--white500);
  }
}

.white-250 {
  color: var(--white250);

  &-bg {
    background-color: var(--white250);
  }
}

.error-color {
  color: var(--errorColor);

  &-bg {
    background-color: var(--errorColor);
  }
}

.success-color {
  color: var(--successColor);

  &-bg {
    background-color: var(--successColor);
  }
}

.warning-color {
  color: var(--warningColor);

  &-bg {
    background-color: var(--warningColor);
  }
}

.background-color {
  color: var(--backgroundColor);

  &-bg {
    background-color: var(--backgroundColor);
  }
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}

.pre-wrap {
  white-space: pre-wrap;
}

.hover-behaviour {
  &:hover {
    opacity: 0.75;
  }

  &:active {
    opacity: 0.5;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
}
